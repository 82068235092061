<template>
  <a-tabs
    v-model="activityKey"
    @change="change"
    hide-add
    @edit="edit"
    type="editable-card"
  >
    <a-tab-pane key="1" tab="常规知识" :closable="false">
      <a-row :gutter="20" v-if="list.length">
        <a-col class="mb-20" :span="12" v-for="item in list" :key="item.id">
          <user-knowledge-card :dataSource="item" @detail="add">
            <a-popconfirm
              slot="opt"
              title="是否删除该知识库?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="del(item.id)"
            >
              <span type="link" class="cursor-pointer">删除</span>
            </a-popconfirm>
          </user-knowledge-card>
        </a-col>
      </a-row>
      <list-empty v-else></list-empty>
      <div class="text-center" v-if="list.length && total > pagination.limit">
        <a-pagination
          :pageSize="pagination.limit"
          :total="total"
          v-model="current"
          @change="getProKnowledgeList"
        ></a-pagination>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="推荐知识" :closable="false">
      <a-row :gutter="20" v-if="list1.length">
        <a-col class="mb-20" :span="12" v-for="item in list1" :key="item.id">
          <user-knowledge-card :dataSource="item" @detail="add">
            <span
              slot="opt"
              type="link"
              class="cursor-pointer"
              @click="del(item.id)"
              >删除</span
            >
          </user-knowledge-card>
        </a-col>
      </a-row>
      <list-empty v-else></list-empty>
    </a-tab-pane>
    <a-tab-pane
      :tab="pane.name"
      v-for="(pane, index) in panes"
      :key="pane.key"
      :closable="true"
    >
      <div class="p-20 bg-white">
        <a-form-model
          :ref="pane.key"
          :labelCol="{ span: 5 }"
          :wrapperCol="{ span: 15 }"
          :model="pane.data"
          :rules="rules"
          labelAlign="left"
          :colon="false"
        >
          <a-form-model-item prop="categoryId" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>种类</span>
            </div>

            <a-select size="large" v-model="pane.data.categoryId">
              <a-select-option
                v-for="option in options.KnowledgeClass"
                :key="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="name" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>标题</span>
            </div>

            <gf-input size="large" v-model="pane.data.name"> </gf-input>
          </a-form-model-item>
          <a-form-model-item prop="desc">
            <div slot="label" class="h-40 flex items-center">
              <span>知识描述</span>
            </div>

            <gf-textarea
              :required="true"
              prop="desc"
              v-model="pane.data.desc"
              :rows="4"
            >
            </gf-textarea>
          </a-form-model-item>
          <a-form-model-item prop="source" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>来源</span>
            </div>

            <gf-input size="large" v-model="pane.data.source"> </gf-input>
          </a-form-model-item>

          <a-form-model-item
            prop="sourceLink"
            v-if="pane.data.categoryId === 7"
          >
            <div slot="label" class="h-40 flex items-center">
              <span>来源网址</span>
            </div>

            <gf-input size="large" v-model="pane.data.sourceLink"> </gf-input>
          </a-form-model-item>
          <a-form-model-item prop="publishTime">
            <div slot="label" class="h-40 flex items-center">
              <span>发布时间</span>
            </div>
            <a-date-picker
              placeholder="请选择年/月/日"
              valueFormat="x"
              size="large"
              v-model="pane.data.publishTime"
            ></a-date-picker>
          </a-form-model-item>
          <a-form-model-item prop="author" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>作者</span>
            </div>
            <gf-input
              size="large"
              prop="author"
              :max="10"
              v-model="pane.data.author"
            >
            </gf-input>
          </a-form-model-item>
          <!-- <a-form-model-item prop="expert" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>专家</span>
            </div>
            <a-input size="large" mode="tags" v-model="pane.data.expert"></a-input>
          </a-form-model-item> -->
          <!-- <a-form-model-item prop="FieldIDs">
            <div slot="label" class="h-40 flex items-center">
              <span>涉及领域</span>
            </div>
            <a-select
              size="large"
              mode="multiple"
              v-model="pane.data.FieldIDs"
              @change="change1"
            >
              <a-select-option
                v-for="option in field"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            prop="Field"
            v-if="pane.data.FieldIDs.includes(-1)"
          >
            <div slot="label" class="h-40 flex items-center">
              <span>自定义领域</span>
            </div>

            <a-select
              mode="tags"
              size="large"
              v-model="pane.data.Field"
            ></a-select>
          </a-form-model-item> -->
          <a-form-model-item prop="IndustryIDs">
            <div slot="label" class="h-40 flex items-center">
              <span>涉及行业</span>
            </div>
            <a-select
              size="large"
              mode="multiple"
              v-model="pane.data.IndustryIDs"
              @change="change2"
            >
              <a-select-option
                v-for="option in industry"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            prop="Industry"
            v-if="pane.data.IndustryIDs.includes(-1)"
          >
            <div slot="label" class="h-40 flex items-center">
              <span>自定义行业</span>
            </div>

            <a-select
              mode="tags"
              size="large"
              v-model="pane.data.Industry"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item prop="TagIDs">
            <div slot="label" class="h-40 flex items-center">
              <span>涉及技术</span>
            </div>
            <a-select
              size="large"
              mode="multiple"
              v-model="pane.data.TagIDs"
              @change="change3"
            >
              <a-select-option
                v-for="option in tag"
                :key="option.ID"
                :value="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="Tag" v-if="pane.data.TagIDs.includes(-1)">
            <div slot="label" class="h-40 flex items-center">
              <span>自定义技术</span>
            </div>

            <a-select
              mode="tags"
              size="large"
              v-model="pane.data.Tag"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item prop="attachments">
            <div slot="label" class="h-40 flex items-center">
              <span>上传附件</span>
            </div>

            <file-upload
              :multiple="false"
              field="3"
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="pane.data.attachments"
            ></file-upload>
          </a-form-model-item>
          <a-form-model-item prop="isRecommend" :wrapperCol="{ span: 9 }">
            <div slot="label" class="h-40 flex items-center">
              <span>是否推荐该知识内容</span>
            </div>

            <a-select size="large" v-model="pane.data.isRecommend">
              <a-select-option
                v-for="option in options.whether"
                :key="option.ID"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="cover" v-if="pane.data.isRecommend === 1">
            <div slot="label" class="h-40 flex items-center">
              <span>推荐位封面</span>
            </div>
            <picture-upload
              v-model="pane.data.cover"
              :width="338"
              :height="160"
              accept=".png,.jpg,.jpeg"
              :size="5"
              field="0"
            ></picture-upload>
          </a-form-model-item>
          <a-form-model-item prop="weight" v-if="pane.data.isRecommend === 1">
            <div slot="label" class="h-40 flex items-center">
              <span>选择推荐位显示位置</span>
            </div>

            <recommend-layout style="height: 200px">
              <div slot="a">
                <span v-if="pane.weights.includes(5)">该位置已占用</span>
                <a-radio
                  v-else
                  @click="changeWeight(pane.data, $event)"
                  :checked="pane.data.weight === 5"
                  :value="5"
                >
                  A区域
                </a-radio>
              </div>
              <div slot="b">
                <span v-if="pane.weights.includes(4)">该位置已占用</span>
                <a-radio
                  v-else
                  @click="changeWeight(pane.data, $event)"
                  :checked="pane.data.weight === 4"
                  :value="4"
                >
                  B区域
                </a-radio>
              </div>
              <div slot="c">
                <span v-if="pane.weights.includes(3)">该位置已占用</span>
                <a-radio
                  v-else
                  @click="changeWeight(pane.data, $event)"
                  :checked="pane.data.weight === 3"
                  :value="3"
                >
                  C区域
                </a-radio>
              </div>
              <div slot="d">
                <span v-if="pane.weights.includes(2)">该位置已占用</span>
                <a-radio
                  v-else
                  @click="changeWeight(pane.data, $event)"
                  :checked="pane.data.weight === 2"
                  :value="2"
                >
                  D区域
                </a-radio>
              </div>
              <div slot="e">
                <span v-if="pane.weights.includes(1)">该位置已占用</span>
                <a-radio
                  v-else
                  @click="changeWeight(pane.data, $event)"
                  :checked="pane.data.weight === 1"
                  :value="1"
                >
                  E区域
                </a-radio>
              </div>
            </recommend-layout>
          </a-form-model-item>
        </a-form-model>
        <div class="text-right">
          <a-button type="primary" @click="save(pane, index)">保存</a-button>
        </div>
      </div>
    </a-tab-pane>
    <a-space slot="tabBarExtraContent" :size="20">
      <a-button
        type="primary"
        @click="add({ id: '3', name: '新增知识', type: 'create' })"
      >
        新增知识
      </a-button>

      <a-input-search
        v-model="name"
        @search="getProKnowledgeList"
        :disabled="activityKey !== '1'"
      ></a-input-search>
    </a-space>
  </a-tabs>
</template>

<script>
import {
  getProKnowledgeList,
  getProRecommonendKnowledgeList,
  deleteProKnowledge,
  createProKnowledge,
  putProKnowledge,
  getProKnowledgeDetail,
} from "@/api/knowledge.js";
function validate1($this) {
  return (rule, value, callback) => {
    const dataSource = $this.panes.find(
      (item) => item.key === $this.activityKey
    ).data;
    let total = dataSource.FieldIDs.length + dataSource.Field.length;
    if (dataSource.FieldIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 4) {
      return callback(new Error("最多只能选择4个"));
    }

    callback();
  };
}
function validate2($this) {
  return (rule, value, callback) => {
    const dataSource = $this.panes.find(
      (item) => item.key === $this.activityKey
    ).data;
    let total = dataSource.IndustryIDs.length + dataSource.Industry.length;
    if (dataSource.IndustryIDs.includes(-1)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 5) {
      return callback(new Error("最多只能选择5个"));
    }

    callback();
  };
}
function validate3($this) {
  return (rule, value, callback) => {
    const dataSource = $this.panes.find(
      (item) => item.key === $this.activityKey
    ).data;
    let total = dataSource.TagIDs.length + dataSource.Tag.length;
    if (dataSource.TagIDs.includes($this.tag.length)) {
      total--;
    }
    if (total === 0) {
      return callback(new Error("请选择"));
    }

    if (total > 10) {
      return callback(new Error("最多只能选择10个"));
    }

    callback();
  };
}
export default {
  data() {
    return {
      activityKey: "1",
      list: [],
      list1: [],
      name: "",
      total: 0,
      current: 1,
      pagination: {
        limit: 8,
        offset: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        publishTime: [{ required: true, message: "请输入", trigger: "blur" }],
        author: [{ required: true, message: "请输入", trigger: "blur" }],
        categoryId: [{ required: true, message: "请选择", trigger: "blur" }],
        isRecommend: [{ required: true, message: "请选择", trigger: "blur" }],
        cover: [{ required: true, message: "请输入", trigger: "blur" }],
        weight: [{ required: true, message: "请选择", trigger: "blur" }],
        source: [{ required: true, message: "请输入", trigger: "blur" }],
        desc: [{ required: true, message: "请输入", trigger: "blur" }],
        IndustryIDs: [
          { required: "true", message: "请选择", trigger: "blur" },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        Industry: [
          { required: "true", message: "请填写", trigger: "blur" },
          { validator: validate2(this), trigger: ["change", "blur"] },
        ],
        FieldIDs: [
          { required: "true", message: "请选择", trigger: "blur" },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        Field: [
          { required: "true", message: "请填写", trigger: "blur" },
          { validator: validate1(this), trigger: ["change", "blur"] },
        ],
        TagIDs: [
          { required: "true", message: "请选择", trigger: "blur" },
          { validator: validate3(this), trigger: ["change", "blur"] },
        ],
        Tag: [
          { required: "true", message: "请填写", trigger: "blur" },
          { validator: validate3(this), trigger: ["change", "blur"] },
        ],
        expert: [{ required: "true", message: "请填写", trigger: "blur" }],
      },
      weights: [],
      panes: [],
      fieldHelp: "",
      fieldStatus: "",
    };
  },
  inject: ["options"],
  created() {
    this.getProKnowledgeList();
    this.getProRecommonendKnowledgeList();
  },
  computed: {
    industry() {
      return this.options.industry;
    },
    field() {
      return this.options.field;
    },
    tag() {
      return this.options.tag;
    },
    options1() {
      return this.$stores.state.options;
    },
  },
  methods: {
    edit(key, action) {
      this[action](key);
    },
    add(data) {
      if (this.panes.length >= 4) {
        this.$message.warn("不能打开更多的tab");
        return;
      }
      if (this.panes.find((item) => item.key === data.id)) {
        this.activityKey = data.id;
        return;
      }
      if (data.type) {
        this.panes.push({
          key: data.id,
          type: data.type,
          data: {
            IndustryIDs: [],
            Industry: [],
            TagIDs: [],
            Tag: [],
          },
          weights: this.weights.filter((item) => item !== data.weight),
          name: data.name,
        });
      } else {
        getProKnowledgeDetail(data.id)
          .then((res) => {
            this.panes.push({
              key: data.id,
              type: data.type,
              data: res,
              weights: this.weights.filter((item) => item !== data.weight),
              name: data.name,
            });
          })
          .catch((err) => {
            console.log(err, "获取详情失败");
          });
      }

      this.activityKey = data.id;
    },
    remove(key) {
      this.panes.forEach((item, index) => {
        if (key === item.key) {
          this.panes.splice(index, 1);
          if (index === 0) {
            this.activityKey = "1";
            this.getList("1");
          } else {
            this.activityKey = this.panes[index - 1].key;
          }
        }
      });
    },
    generateParams() {
      let temp = {
        name: this.name,
        limit: this.pagination.limit,
        offset: this.pagination.limit * (this.current - 1),
      };
      return temp;
    },
    getProKnowledgeList() {
      const params = this.generateParams();
      getProKnowledgeList(params).then((data) => {
        this.total = data.count;
        this.list = data.rows || [];
      });
    },
    getProRecommonendKnowledgeList() {
      getProRecommonendKnowledgeList().then((data) => {
        this.list1 = data.rows || [];
        this.weights = data.rows.map((item) => item.weight);
      });
    },
    getList(e) {
      if (e === "1") {
        this.getProKnowledgeList();
      } else if (e === "2") {
        this.getProRecommonendKnowledgeList();
      }
    },
    change(e) {
      this.name = "";
      this.current = 1;
      this.pagination = {
        limit: 8,
        offset: 0,
      };
      this.getList(e);
    },
    del(id) {
      deleteProKnowledge(id).then(() => {
        this.$message.success("删除知识成功");
        this.getList(this.activityKey);
      });
    },
    restore(obj) {
      const temp = {
        Title: obj.name,
        Desc: obj.desc,
        Source: obj.source,
        Link: obj.sourceLink,
        Cover: obj.cover,
        Attachments: obj.attachments,
        Weight: obj.weight,
        Fields: [],
        Industries: [],
        Tags: [],
        Category: {},
        Author: obj.author,
      };
      temp.PublishedAt = new Date(+obj.publishTime).toISOString();
      if (obj.categoryId) {
        temp.Category = this.options.KnowledgeClass.find(
          (item) => item.ID === obj.categoryId
        );
      }

      temp.Industries.push(
        ...obj.IndustryIDs.map((item) => this.options.industry[item - 1])
      );
      if (obj.IndustryIDs.includes(-1)) {
        temp.Industries.push(
          ...obj.Industry.map((item) => {
            return {
              ID: 0,
              Name: item,
            };
          })
        );
      }

      temp.Tags.push(...obj.TagIDs.map((item) => this.options.tag[item - 1]));
      if (obj.TagIDs.includes(-1)) {
        temp.Tags.push(
          ...obj.Tag.map((item) => {
            return {
              ID: 0,
              Name: item,
            };
          })
        );
      }

      return temp;
    },
    save(pane, index) {
      const data = pane.data;
      this.$refs[pane.key][0].validate((valid) => {
        if (valid) {
          const temp = this.restore(data);
          if (data.id) {
            putProKnowledge(data.id, temp)
              .then(() => {
                this.$message.success("保存成功");
              })
              .catch((err) => {
                console.log(err, "保存失败");
              });
          } else {
            createProKnowledge(temp)
              .then(() => {
                this.$message.success("创建成功");
                this.panes.splice(index, 1);
                if (data.weight) {
                  this.activityKey = "2";
                  this.change("2");
                } else {
                  this.activityKey = "1";
                  this.change("1");
                }
              })
              .catch((err) => {
                console.log(err, "创建失败");
              });
          }
        }
      });
    },
    changeWeight(data, e) {
      this.$set(data, "weight", +e.target.value);
    },
    change1(e, data) {
      if (!e.includes(-1)) {
        this.$set(data, "Field", []);
      }
    },
    change2(e, data) {
      if (!e.includes(-1)) {
        this.$set(data, "Industry", []);
      }
    },
    change3(e, data) {
      if (!e.includes(-1)) {
        this.$set(data, "Tag", []);
      }
    },
  },
};
</script>